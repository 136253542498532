import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import PostSnippetCardLandingPages from "../components/PostSnippetCardLandingPages";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ThreeLinkBlock from "../components/ThreeLinkBlock";

const ReportsPageTemplate = ({ heroImage, intro, posts: originalPosts }) => {
  const posts = [...originalPosts];
  let firstPost = posts[0];
  const stickyIndex = posts.findIndex(a => a.frontmatter.sticky);
  if (stickyIndex > -1) {
    firstPost = posts[stickyIndex];
    posts.splice(stickyIndex, 1);
  } else {
    posts.splice(0, 1);
  }

  return (
    <>
      <Row className="py-5 laas-half-bg">
        <Col className="col-12 col-sm-10 offset-sm-1">
          <div style={{ position: "relative" }}>
            <PreviewCompatibleImage
              imageInfo={heroImage}
              style={{ maxHeight: "550px" }}
            />

            <div className="hero-tag">
              <div>
                <i className="text-white fas fa-caret-down"></i>
              </div>
              <h2 className="text-white font-cb text-uppercase">Reports</h2>
            </div>
          </div>
        </Col>
        <Col className="col-12 col-sm-8 offset-sm-2 pt-4">
          <p className="text-line-height text-white font-cr">{intro}</p>
        </Col>
      </Row>
      <Row className="pb-5 pt-4">
        <Col className="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
          <div className="laas-text-dr">
            <i className="fas fa-caret-down laas-text-dr"></i>
          </div>
          <h3 className="laas-text-dr font-cb text-uppercase">
            {firstPost.frontmatter.headline}
          </h3>
          <div className="card border-0 bg-transparent">
            <h5 className="card-title text-dark font-cb">
              <strong>{firstPost.frontmatter.title}</strong>
            </h5>
            <div
              className="laas-text-dr pb-3 w-25"
              style={{ borderTop: "1px solid #921004" }}
            ></div>
            <PreviewCompatibleImage imageInfo={firstPost.frontmatter.image} />
            <div className="font-cr">
              <div className="card-text text-truncate text-wrap pt-4">
                {firstPost.excerpt}
              </div>
              <Link className="font-cb laas-text-br" to={firstPost.fields.slug}>
                Read more...
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {posts.map(post => (
          <Col
            className="col-10 offset-1 col-sm-5 offset-sm-1 mx-auto pb-5"
            key={post.id}
            style={{ maxWidth: "400px" }}
          >
            <PostSnippetCardLandingPages
              title={post.frontmatter.title}
              headline={post.frontmatter.headline}
              excerpt={post.excerpt}
              slug={post.fields.slug}
              image={post.frontmatter.image}
            />
          </Col>
        ))}
      </Row>
      <ThreeLinkBlock />
    </>
  );
};

const ReportsPage = ({ data }) => {
  return (
    <Layout>
      <ReportsPageTemplate
        heroImage={data.page.frontmatter.heroImage}
        intro={data.page.frontmatter.intro}
        posts={data.posts.nodes}
      />
    </Layout>
  );
};

export default ReportsPage;

export const pageQuery = graphql`
  query ReportsPageTemplate {
    page: markdownRemark(frontmatter: { templateKey: { eq: "reports-page" } }) {
      frontmatter {
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        intro
        overlay
      }
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "reports-post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          sticky
          title
          headline
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 250, height: 250) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        id
        excerpt(pruneLength: 250)
        fields {
          slug
        }
      }
    }
  }
`;
