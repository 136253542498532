import React from "react";
import { Link } from "gatsby";

import Img from "gatsby-image";

export default ({ headline, title, image, slug, excerpt }) => (
  <>
    <div className="laas-text-dr">
      <i className="fas fa-caret-down"></i>
    </div>
    <Link to={slug}>
      <h4 className="two-line-height text-wrap text-truncate laas-text-dr font-cb text-uppercase">
        {headline}
      </h4>
    </Link>
    <div className="card border-0 bg-transparent pt-1 font-cr">
      <Link to={slug}>
        <h5 className="card-title text-dark two-line-height text-wrap text-truncate font-cb">
          <strong>{title}</strong>
        </h5>
      </Link>
      <div
        className="laas-text-dr pb-3 w-25"
        style={{ borderTop: "1px solid #921004" }}
      ></div>
      <Link to={slug}>
        <Img className="" fluid={image.childImageSharp.fluid} />
      </Link>
      <div className="pt-4">
        <div className="card-text text-truncate text-wrap text-line-height font-cr">
          {excerpt}
        </div>
        <Link className="laas-text-br font-cb" to={slug}>
          Read more...
        </Link>
      </div>
    </div>
  </>
);
